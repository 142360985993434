<template>
  <transition enter-active-class="animate__animated animate__fadeInLeft">
    <div class="banner-wrap" v-if="animate">
      <el-carousel v-bind="carouselConfig" trigger="click" :interval="10000">
        <el-carousel-item v-for="p in bannerPictures" :key="p">
          <el-image
            style="height: 100%"
            :src="'/' + p"
            fit="contain"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="bg-ship"> -->
      <!-- <video
        :controls="false"
        loop
        autoplay
        class="bg-ship"
        enable-play-gesture="true"
      >
        <source
          src="../../assets/bg-video.mp4"
          type="video/mp4"
          autostart="true"
        />
      </video> -->
      <!-- </div> -->
      <!-- <div id="banner">
        <div class="banner-content">
          <transition
            enter-active-class="animate__animated animate__backInDown"
          >
            <p class="banner-content-item" v-show="bannerTitle1">
              {{ i18n.bannerData[0] }}
            </p>
          </transition>
          <transition
            enter-active-class="animate__animated animate__backInLeft"
          >
            <p class="banner-content-item" v-show="bannerTitle2">
              {{ i18n.bannerData[1] }}
            </p>
          </transition>
          <transition
            enter-active-class="animate__animated animate__backInRight"
          >
            <p class="banner-content-item" v-show="bannerTitle3">
              {{ i18n.bannerData[2] }}
            </p>
          </transition>
        </div>
      </div> -->
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      animate: false,
      bannerTitle1: false,
      bannerTitle2: false,
      bannerTitle3: false,
    };
  },

  computed: {
    bannerPictures() {
      if (this.isCnLocale()) {
        return ["banner3-cn.png", "banner2-cn.png", "banner1-cn.png"];
      }
      return ["banner3.png", "banner2.png", "banner1.png"];
    },

    carouselConfig() {
      if (this.isMobile) {
        return {
          width: "100vw",
          height: "400px",
        };
      }
      return {
        width: "100vw",
        height: "calc(100vh - 70px)",
      };
    },
  },

  mounted() {
    setTimeout(() => {
      this.animate = true;
    });

    setTimeout(() => {
      this.bannerTitle1 = true;
    }, 1000);

    setTimeout(() => {
      this.bannerTitle2 = true;
    }, 2000);

    setTimeout(() => {
      this.bannerTitle3 = true;
    }, 3000);
  },
};
</script>


<style lang="scss" scoped>
.banner-wrap {
  overflow: hidden;
  position: relative;
  // height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #000;
  margin-top: 70px;
  // user-select: none;

  .bg-ship {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/home-main-one.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.4;

    // position: absolute;
    // top: 0;
    // left: 0;
    // // z-index: -100;
    // width: 100vw;
    // height: 100vh;
    // object-fit: cover;
  }

  @media screen and (max-width: 1000px) {
    /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
    .grid-2 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 24px;
      align-items: start;
    }
  }

  #banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;

    .banner-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20%;

      &-item {
        color: #fff;
        font-weight: bolder;
        font-size: 36px;
      }
    }
  }
}
</style>