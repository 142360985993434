<script>
import PlaceHolderImage from "../../assets/placeholder.png";
import { mapState } from "vuex";
import dataSheetNameList from "../../dataSheet.js";

const InitProduct = {
  title: "",
  subTitle: "",
  features: [],
  descriptions: [],
  applications: [],
  pictures: [],
};

export default {
  name: "Products",
  data() {
    return {
      selectedProduct: "",

      product: { ...InitProduct },
      PlaceHolderImage,
      isComingSoon: false,
    };
  },

  computed: {
    ...mapState({
      currentProduct: (state) => state.currentProduct,
    }),
  },

  watch: {
    currentProduct: {
      immediate: true,
      handler: function () {
        this.selectedProduct = this.currentProduct;
        this.handleSelectChange();
      },
    },
  },

  mounted() {
    const pid = this.$route.query.pid;
    if (pid) {
      this.selectedProduct = [pid];
      this.handleSelectChange();
    }
  },

  methods: {
    setIsComingSoon() {
      console.log(this.selectedProduct);
      const name = this.selectedProduct[this.selectedProduct.length - 1];
      this.isComingSoon = !dataSheetNameList.some((item) => item === name);
    },
    handleSelectChange() {
      const name = this.selectedProduct[this.selectedProduct.length - 1];
      if (!name) return;
      const product = this.i18n.productList[name];
      this.setIsComingSoon();
      if (!product) {
        this.$message.warning(`未找到${name}的产品信息`);
        this.product = { ...InitProduct };
        return;
      }
      this.product = product;
    },
    handleDownload(product) {
      if (this.isComingSoon) return;
      window.open(`/files/${product.title}.pdf`);
    },
  },
};
</script>

<template>
  <div class="products">
    <!-- <div class="products-select">
      <el-cascader
        v-model="selectedProduct"
        :options="i18n.productNameOptions"
        :placeholder="i18n.productSelectTip"
        @change="handleSelectChange"
      >
      </el-cascader>
    </div> -->
    <div v-if="product.title">
      <div class="title products-section">{{ product.title }}</div>
      <div class="sub-title products-section">
        <div class="sub-title__item">
          {{ product.subTitle }}
          <!-- <i
            class="el-icon-download sub-title__download"
            @click="handleDownload(product)"
          ></i> -->
          <span class="sub-title__download" @click="handleDownload(product)">
            {{ isComingSoon ? i18n.comingSoon : i18n.dataSheet }}
          </span>
        </div>
      </div>

      <template v-if="product.features">
        <div class="grid-2 products-section">
          <div class="pictures">
            <div class="item-title">{{ i18n.productPictureTip }}</div>
            <el-carousel height="400px" width="600px">
              <el-carousel-item v-for="p in product.pictures" :key="p">
                <el-image
                  style="width: 600px; height: 400px"
                  :src="'/' + p"
                  fit="contain"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="features">
            <div class="item-title">{{ i18n.productFeatureTip }}</div>
            <p class="features_item" v-for="f in product.features" :key="f">
              <i
                class="el-icon-check"
                style="color: #33a1d0; font-weight: bold; margin-right: 12px"
              ></i>
              <span>{{ f }}</span>
            </p>
          </div>
        </div>

        <div class="grid-2 products-section">
          <div class="descriptions products-section">
            <div class="item-title">{{ i18n.productDescTip }}</div>
            <p
              class="descriptions_item"
              v-for="d in product.descriptions"
              :key="d"
            >
              {{ d }}
            </p>
          </div>

          <div class="applications products-section">
            <div class="item-title">{{ i18n.productionApplicationTip }}</div>
            <p
              class="application_item"
              v-for="a in product.applications"
              :key="a"
            >
              {{ a }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!product.features" class="placeholder-image">
      <el-image
        :src="PlaceHolderImage"
        style="width: 100%; max-width: 400px"
      ></el-image>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../style/global.scss";
.products {
  min-height: 100vh;
  padding: 70px 0 120px;
  text-align: left;
  margin: 0 120px 0;
  font-size: 16px;

  &-select {
    margin-top: 24px;
    .el-cascader {
      width: 100%;
    }
  }

  &-section {
    margin-top: 8px;
  }

  .item-title {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    margin: 0;
    font-size: 24px;
    line-height: 1;

    position: relative;
    &::before {
      @extend .vertical-decoration;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    position: relative;
    &::before {
      @extend .vertical-decoration;
    }
  }

  .sub-title {
    padding: 12px;
    border-radius: 8px;
    border: solid 1px #e5ebf2;
    position: relative;
    background-color: rgba(244, 247, 255, 0.85);
    margin-bottom: 16px;

    &__download {
      display: inline-block;
      margin-left: 16px;
      font-weight: bold;
      color: #33a1d0;
      cursor: pointer;
    }

    &__item {
      font-size: 16px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      padding-left: 10px;
      padding-right: 5px;
    }
  }

  .pictures {
    .el-carousel {
      width: 600px;
    }
  }

  .descriptions {
    width: 100%;
    border: solid 1px #e5ebf2;
    background-color: #f9fbff;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 20px 10px;
    overflow: hidden;
    color: #4c4c4c;
    line-height: 2;
  }

  .features {
    width: 100%;
    border: solid 1px #e5ebf2;
    background-color: #f9fbff;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 20px 10px;
    overflow: hidden;
  }

  .applications {
    width: 100%;
    border: solid 1px #e5ebf2;
    background-color: #f9fbff;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 20px 10px;
    overflow: hidden;
  }

  .placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    margin-bottom: 20%;
  }
}
</style>