import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: { currentProduct: "" },
  actions: {
    CHANGE_PRODUCT: ({ state }, value) => {
      state.currentProduct = value;
    },
  },
});

export default store;
