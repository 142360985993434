import { CnProductList, CnProductNameList } from "./products";
const data = {
  header: {
    menus: [
      {
        label: "公司简介",
        href: "#company",
      },
      {
        label: "核心技术",
        href: "#technology",
      },
      {
        label: "公司产品",
        href: "#products",
      },
      {
        label: "招聘信息",
        href: "#jd",
      },
      {
        label: "联系我们",
        href: "#contact",
      },
    ],
  },

  bannerData: [
    "核『芯』技术开发最优质量芯片",
    "创『芯』设计打造高效可靠产品",
    "尽『芯』打造世界一流半导体品牌",
  ],
  company: {
    title: "公司简介",
    desc: [
      "<span style='color: #33a1d0; font-weight: bolder'>六式格玛半导体 (6 Sigma Semiconductor)</span>，致力于设计新一代高性能电源管理产品。",
      "公司名字 - <span style='color: #33a1d0; font-weight: bolder'>六式格玛 (6 Sigma)</span> - 来自半导体行业最高等的质量管理方法。六式格玛的使命是成为中国本土最优质可靠的半导体品牌。",
      `六式格玛创建团队由来自国内外的模拟芯片专家组成，团员毕业于国内外一流大学如斯坦福大学 (Stanford University)，伯克利加州大学 (UC Berkeley)，麻省理工学院 (MIT)，上海交通大学，香港科技大学等等，各自有超过20年在世界一流半导体公司的经验。
`,
    ],
  },
  technology: {
    title: "核心技术",
    desc: [
      `六式格玛的技术结合了创新的工艺技术和独家的电路设计，通过提供更小的解决方案尺寸，更好的电源效率，更少的散热和更长的可靠性寿命来大为减低成本,
    并大大提高产品质量。`,
    ],
  },
  jd: {
    title: "招聘信息",
    jobs: ["应用工程师", "测试工程师", "版图设计师"],
  },
  contact: {
    title: "联系我们",
    desc: [
      "六式格玛半导体科技(深圳)有限公司",
      "总部：深圳 - 前海深港合作区南山街道前海梦工场5号楼107室",
      "分部：香港，上海，美国硅谷",
      "销售联系方式：18665910138（微信同号）  fguan@6sigmasemi.com",
    ],
  },
  productList: CnProductList,

  productPictureTip: "典型应用电路",
  productFeatureTip: "产品特性",
  productDescTip: "产品描述",
  productionApplicationTip: "产品应用",

  productSelectTip: "请选择一个产品",

  productNameOptions: CnProductNameList,

  dataSheet: "规格书",
  comingSoon: "即将发布",
};

export default data;
