<template>
  <div id="technology">
    <transition enter-active-class="animate__animated animate__fadeInTopLeft">
      <div class="tech-left" v-show="technologyShow">
        <h2 class="tech-left-title">{{ content.title }}</h2>
        <p v-for="(item, idx) in content.desc" :key="idx">
          {{ item }}
        </p>
      </div>
    </transition>
    <transition enter-active-class="animate__animated animate__bounceInRight">
      <div class="tech-right" v-show="technologyShow">
        <div
          v-for="(item, index) in techData"
          :key="index"
          class="tech-right-item"
        >
          <img :src="item.url" alt="" class="tech-right-item__img" />
          <span class="tech-right-item__label" v-if="isCnLocale()">
            {{ item.label }}
          </span>
          <span class="tech-right-item__enlabel" v-else>
            {{ item.enLabel }}
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import efficency from "../../assets/efficency.png";
import frequency from "../../assets/frequency.png";
import solution from "../../assets/solution.png";
import fast from "../../assets/speed.png";
import ultralow from "../../assets/ultralow.png";
export default {
  props: {
    elementInView: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      technologyShow: true,
      techData: [
        {
          label: "高效能设计",
          enLabel: "High Efficiency",
          url: efficency,
        },
        {
          label: "高频控制",
          enLabel: "High Frequency",
          url: frequency,
        },
        {
          label: "小型解决方案",
          enLabel: "Small Solution",
          url: solution,
        },
        {
          label: "超快瞬态响应",
          enLabel: "Super-Fast Transient",
          url: fast,
        },
        {
          label: "超低耗电设计",
          enLabel: "Ultra Low lq",
          url: ultralow,
        },
      ],
    };
  },

  computed: {
    content() {
      return this.i18n.technology;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.scroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },

  methods: {
    scroll() {
      this.elementInView("technology", () => {
        this.technologyShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/global.scss";

#technology {
  background-color: #f9f9f9;
  width: 100%;
  padding: 60px 80px 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tech-left {
    max-width: 600px;
    // margin-left: 10%;
    align-self: center;
    text-align: justify;
    text-justify: inter-ideograph;

    &-title {
      position: relative;
      text-align: center;
      &::before {
        @extend .underline-decoration;
      }
    }

    p {
      color: #4c4c4c;
      line-height: 2;
      font-size: 16px;
    }
  }

  .tech-right {
    width: 100%;
    margin-top: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin-right: 24px;
      user-select: none;

      &:hover {
        color: #33a1d0 !important;
        transform: scale(1.05);
      }

      &__img {
        width: 64px;
        height: 64px;
        color: red;
        pointer-events: none;
      }

      &__label {
        margin-top: 16px;
        font-size: 18px;
        font-weight: 800;
      }

      &__enlabel {
        margin-top: 12px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
</style>