<template>
  <div class="header" :style="{ opacity: headerOpacity }">
    <div class="header-logo">
      <img
        src="../../assets/sigma-logo.jpg"
        alt="logo"
        class="header-logo-content"
        @click="handleClickLogo"
        ondragstart="return false;"
      />
    </div>
    <div class="header-content">
      <ul class="nav">
        <li
          class="nav-link"
          v-for="item in content.menus"
          :key="item.label"
          @click="handleJump(item.href)"
        >
          <!-- <el-popover
            placement="bottom-start"
            trigger="click"
            :offset="-200"
            :visible-arrow="true"
            v-if="item.href === '#products'"
          > -->
          <el-cascader
            class="my-cascader"
            v-if="item.href === '#products'"
            :options="i18n.productNameOptions"
            :placeholder="i18n.productSelectTip"
            @change="handleSelectProduct"
          >
          </el-cascader>
          <span v-if="item.href === '#products'">{{ item.label }}</span>
          <!-- <span slot="reference">{{ item.label }}</span> -->
          <!-- </el-popover> -->
          <span v-else>{{ item.label }}</span>
        </li>

        <li class="nav-link" v-if="isCnLocale()" @click="handleToEn">EN</li>
        <li class="nav-link" v-else @click="handleToCn">中文</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerOpacity: 0.6,
    };
  },

  computed: {
    content() {
      return this.i18n.header;
    },
    locale() {
      return `${this.isCnLocale() ? "" : "/en"}`;
    },
    isInProductRoute() {
      const currentPath = this.$route.path;
      const isProducts = currentPath.indexOf("/products") !== -1;
      return isProducts;
    },
  },

  watch: {
    isInProductRoute(v) {
      if (v) {
        this.headerOpacity = 1;
      }
    },
  },

  created() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleWindowScroll);
    });
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleWindowScroll);
  },

  methods: {
    handleToEn() {
      window.location.href = "#/home/en";
      window.location.reload();
    },
    handleToCn() {
      window.location.href = "#/home";
      window.location.reload();
    },
    handleWindowScroll() {
      if (this.isInProductRoute) {
        return;
      }
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight = document.documentElement.clientHeight;

      const opacity = scrollTop / windowHeight;
      this.headerOpacity = Math.max(0.6, opacity);
    },
    handleJump(id) {
      const currentPath = this.$route.path;

      const isProducts = currentPath.indexOf("/products") !== -1;
      if (id === "#products") {
        if (!isProducts) {
          const products = this.$store.state.currentProduct;
          console.log(products, ">>>>");
          if (products) {
            this.$router.push({
              path: `/products${this.locale}`,
              query: {
                pid: products[products.length - 1],
              },
            });
            this.headerOpacity = 1;
          }
        }
        return;
      }
      const isHome = currentPath.indexOf("/home") !== -1;
      if (!isHome) {
        this.$router.push(`/home${this.locale}`);
      }
      setTimeout(
        () => {
          const anchorEle = document.querySelector(id);
          anchorEle && anchorEle.scrollIntoView(true);
        },
        isHome ? 0 : 500
      );
    },

    handleClickLogo() {
      const isCn = this.isCnLocale();
      if (isCn) {
        this.handleToCn();
      } else {
        this.handleToEn();
      }
    },

    handleSelectProduct(product) {
      this.$store.dispatch("CHANGE_PRODUCT", product);
      this.headerOpacity = 1;
      console.log("asdfasdfasfd", product, this.locale);
      this.$router.push({
        path: `/products${this.locale}`,
        query: {
          pid: product[product.length - 1],
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
ul,
li {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 70px;
  box-sizing: border-box;
  background-color: #222d38;
  padding: 0 120px;
  user-select: none;

  &-logo {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 100px;

    &-content {
      // background: linear-gradient(to right,rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
      border-radius: 4px;
      padding: 10px;
      // height: 36px;
      // background: #fff;
      width: 120px;
      cursor: pointer;
      // box-shadow: 0 0 6px 0px rgba($color: #fff, $alpha: 0.8);

      &:hover {
        // box-shadow: 0 0 6px 0px rgba($color: #fff, $alpha: 1);
        background: rgba($color: #000000, $alpha: 0.2);
      }
    }
  }

  &-content {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .nav {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 1;
      max-width: 600px;

      &-link {
        position: relative;
        font-size: 17px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 20px;
        &:hover {
          color: #33a1d0;
        }

        .my-cascader {
          position: absolute;
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  .header {
    background: red;
  }
}
</style>