<template>
  <div id="contact">
    <transition enter-active-class="animate__animated animate__slideInLeft">
      <!-- <img
        src="../../assets/company-address.jpg"
        alt=""
        class="company-img"
        v-show="contactShow"
      /> -->
      <img
        src="../../assets/location-cn.png"
        alt=""
        class="company-img"
        v-if="isCnLocale()"
      />
      <img
        v-else
        src="../../assets/location-en.png"
        alt=""
        class="company-img"
      />
    </transition>

    <transition
      enter-active-class="animate__animated animate__lightSpeedInRight"
    >
      <div class="contact-wrap" v-show="contactShow">
        <h2 class="title">{{ content.title }}</h2>
        <p v-for="(item, idx) in content.desc" :key="idx">
          {{ item }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    elementInView: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      contactShow: true,
    };
  },

  computed: {
    content() {
      return this.i18n.contact;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.scroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },

  methods: {
    scroll() {
      this.elementInView("contact", () => {
        this.contactShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/global.scss";
#contact {
  background-color: #f9f9f9;
  padding: 80px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .company-img {
    width: 450px;
    height: 320px;
    border-radius: 2px;
    text-align: left;
    flex-shrink: 0;
    margin: 0 60px;
    pointer-events: none;
  }

  .contact-wrap {
    max-width: 600px;
    text-align: justify;
    text-justify: inter-ideograph;

    .title {
      position: relative;
      &::before {
        @extend .underline-decoration;
      }
    }
    p {
      color: #4c4c4c;
      font-size: 16px;
      line-height: 2;
    }
  }
}
</style>