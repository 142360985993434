<template>
  <div class="footer">
    <p>
      Copyright © {{ currentYear }}
      <span v-if="isCnLocale()"> 六式格玛半导体 </span>
      <span v-else> 6 Sigma Semiconductor</span>
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 64px;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.9);
  color: #f4f4f4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 16px;
}
</style>