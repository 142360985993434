<template>
  <div id="jd">
    <div class="jd-wrap" />
    <h2 class="jd-title">{{ content.title }}</h2>
    <div class="jd-content">
      <div
        class="jd-content-item"
        v-for="(item, index) in content.jobs"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    content() {
      return this.i18n.jd;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/global.scss";

#jd {
  padding: 80px;
  position: relative;
  background-color: #000;
  color: #fff;
  .jd-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/jd-new.jpg");
    background-size: cover;
    background-position: center;
    opacity: 0.3;
  }
  .jd-title {
    position: relative;
    margin-bottom: 36px;
    &::before {
      @extend .underline-decoration;
    }
  }
  .jd-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    max-width: 800px;

    &-item {
      width: 100px;
      height: 60px;
      color: #fff;
      border: 4px solid #33a1d0;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px 36px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 8px;
      transition: all 0.5s linear;

      &:hover {
        background: red;
      }
    }
  }
}
</style>