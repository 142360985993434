import enUs from "./en-us";
import zhCn from "./zh-cn";

export default {
  computed: {
    i18n() {
      const { locale } = this.$route.params;
      if (locale === "en") {
        document.title = "6 Sigma Semiconductor";
        return enUs;
      }
      document.title = "六式格玛半导体科技(深圳)有限公司";
      return zhCn;
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },

  methods: {
    isCnLocale() {
      const { locale } = this.$route.params;
      return locale !== "en";
    },
  },
};
