import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/index.scss";
// import animated from "animate.css";
import i18nMixin from "./i18n/index-mixin";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
Vue.config.productionTip = false;
// Vue.use(animated);
Vue.use(ElementUI);
Vue.mixin(i18nMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
