<template>
  <div id="company">
    <transition enter-active-class="animate__animated animate__slideInLeft">
      <img
        src="../../assets/bg-ship-bridge.jpg"
        alt=""
        class="company-img"
        v-show="commpanyShow"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__lightSpeedInRight"
    >
      <div class="company-content" v-show="commpanyShow">
        <h2 class="title">{{ company.title }}</h2>
        <p v-for="(desc, idx) in company.desc" :key="idx" v-html="desc">
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    elementInView: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      commpanyShow: true,
    };
  },

  computed: {
    company() {
      return this.i18n.company;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.scroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },

  methods: {
    scroll() {
      this.elementInView("company", () => {
        this.commpanyShow = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/global.scss";
#company {
  padding: 80px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  flex-wrap: wrap;
  .company-img {
    width: 450px;
    height: 320px;
    border-radius: 2px;
    text-align: left;
    flex-shrink: 0;
    align-self: center;
    pointer-events: none;
    flex-shrink: 0;
    margin-right: 60px;
  }

  .company-content {
    max-width: 600px;
    text-align: justify;
    text-justify: inter-ideograph;

    .title {
      position: relative;
      &::before {
        @extend .underline-decoration;
      }
    }

    p {
      color: #4c4c4c;
      font-size: 16px;
      line-height: 2;
    }
  }
}
</style>