import { EnProductList, EnProductNameList } from "./products";
const data = {
  header: {
    menus: [
      {
        label: "About Us",
        href: "#company",
      },
      {
        label: "Technology",
        href: "#technology",
      },
      {
        label: "Products",
        href: "#products",
      },
      {
        label: "Jobs",
        href: "#jd",
      },
      {
        label: "Contact Us",
        href: "#contact",
      },
    ],
  },

  bannerData: [
    "State-of-the-Art Designs",
    "Best-in-Class Technology",
    "6 Sigma Product Quality",
  ],
  company: {
    title: "About Us",
    desc: [
      "<span style='color: #33a1d0; font-weight: bolder'>6 Sigma Semiconductor</span> focuses on creating new generation of high performance power management ICs.",
      "The company’s name, 6 Sigma, derives from the highest quality management method in the semiconductor industry. 6 Sigma Semiconductor’s mission is to become the best quality and the most reliable semiconductor brand in the world.",
      "6 Sigma Semiconductor’s founding team is composed of power management experts from China and Silicon Valley in the United States. The team members are graduated from world-renowned universities such as Stanford University, UC Berkeley, MIT, Shanghai Jiaotong University, HKUST, etc.  Each member has more than 20 years of industry experience, and has work experience in world-class semiconductor companies.",
    ],
  },
  technology: {
    title: "Technology",
    desc: [
      `6 Sigma Semiconductor’s technology combines innovative process technology and proprietary circuit design to greatly reduce product costs by offering smaller solution size, better power efficiency, less heat dissipation, longer reliability life, and greatly improve product quality.`,
    ],
  },
  jd: {
    title: "Jobs",
    jobs: ["Application Engineer", "Test Engineer", "Layout Designer"],
  },
  contact: {
    title: "Contact Us",
    desc: [
      "6 Sigma Semiconductor Technology (Shenzhen) Limited",
      "Headquarter in Shenzhen:  Room 107, Building 5, Qianhai Youth Dream Workshop, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen",
      "Branch Offices in Hong Kong, Shanghai, Silicon Valley",
      "Email: fguan@6sigmasemi.com",
    ],
  },

  productList: EnProductList,

  productPictureTip: "Typical Application Circuits",
  productFeatureTip: "Features",
  productDescTip: "Descriptions",
  productionApplicationTip: "Applications",

  productSelectTip: "Please select a product",

  productNameOptions: EnProductNameList,

  dataSheet: "datasheet",

  comingSoon: "Coming soon",
};

export default data;
