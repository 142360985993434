import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: "*",
        redirect: "/home",
      },
      {
        path: "/home/:locale?",
        component: () => import("@/views/home/index"),
      },
      {
        path: "/jd",
        component: () => import("@/views/jd/index"),
      },
      {
        path: "/products",
        component: () => import("@/views/products/index"),
      },
      {
        path: "/products/:locale?",
        component: () => import("@/views/products/index"),
      },
    ],
  });

const router = createRouter();

export default router;
