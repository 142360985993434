<template>
  <div id="app">
    <fixed-header />
    <router-view />
    <uni-footer />
  </div>
</template>

<script>
import FixedHeader from "@/components/header";
import UniFooter from "@/components/footer";

export default {
  name: "App",
  components: { FixedHeader, UniFooter },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
