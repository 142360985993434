export const EnProductList = {
  SX1801: {
    title: "SX1801",
    subTitle: "65nA Low Iq Low Switch",
    features: [
      "Input Voltage Range from 1V to 5.5V",
      "Up to 2A Output Current",
      "Typical Ultra-Low RON – 60mΩ",
      "Low Quiescent Current – 65nA",
      "Internal Soft-start – 200µs(maximum)",
      "Reverse Current Block",
      "Output Short Protection",
      "150Ω Output Discharge",
      "0.77mm x 0.77mm 4-Ball WLCSP Package",
    ],
    descriptions: [
      `The SX1801 is an ultra-efficient load switch with ultra-low quiescent current of typical 65nA. SX1801 operates with input voltage range from 1.0V to 5.5V. It is able to deliver up 2A output current`,
      `The SX1801 provides ultra-low Quiescent Current IQ and Shutdown Current ISD. With reduced leakage current, overall system power efficiency and operation life are improved`,
      `The device provides extremely low 60mΩ on resistance RON. Reverse current blocking and output short protection operates with the ultra-low 100nA IQ. Reverse blocking is activated in both on and off state of the Load Switch`,
    ],
    applications: [
      "IoT",
      "Wearable",
      "SSD",
      "Digital Camera",
      "Battery Powered Devices",
    ],
    pictures: ["SX1801-1.png"],
  },
  SX01P3: {
    title: "SX01P3",
    subTitle:
      "NERO SWITHERTM (Nano-IQ Efficient Rapid Output) 300mA Step-Down Regulator",
    features: [
      "Input Voltage Range from 2.2V to 5.5V Operates with Single Li-ion and LiMnO2",
      "Output Voltage Range from 0.7V to 3.3V",
      "Up to 300mA Output Current",
      "Up to 96% Efficiency",
      "88% Efficiency at 10uA Load Current",
      "NERO SwitcherTM Power Save Mode 360nA Quiescent Current at Light Load",
      "NERO SwitcherTM Rapid Output Load Transient Responses",
      "Internal compensated",
      "2MHz Switching Frequency at PWM",
      "Output voltages options from 0.7V to 3.3V",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `The SX01P3 is a high efficiency step down regulator with ultra-low quiescent current of typical 360nA. SX01P3 operates with input voltage range from 2.2V to 5.5V and it is able to deliver up 300mA output current`,
      `The SX01P3 provides programmable output voltages between 0.7V to 3.3V. The NERO SwitcherTM provides high efficiency down to 10uA load current and rapid load transient responses with switching frequency up to 2MHz at heavy load`,
    ],
    applications: ["IoT", "Wearable", "Bluetooth", "Battery Powered Devices"],
    pictures: ["SX01P3-1.png"],
  },
  SX01P6: {
    title: "SX01P6",
    subTitle:
      "NERO SWITHERTM (Nano-IQ Efficient Rapid Output) 600mA Step-Down Regulator",
    features: [
      "Input Voltage Range from 2.2V to 5.5V Operates with Single Li-ion and LiMnO2",
      "Output Voltage Range from 0.7V to 3.3V",
      "Up to 600mA Output Current",
      "Up to 96% Efficiency",
      "88% Efficiency at 10uA Load Current",
      "NERO SwitcherTM Power Save Mode 360nA Quiescent Current at Light Load",
      "NERO SwitcherTM Rapid Output Load Transient Responses",
      "Internal compensated",
      "2MHz Switching Frequency at PWM",
      "Output voltages options from 0.7V to 3.3V",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `The SX01P6 is a high efficiency step down regulator with ultra-low quiescent current of typical 360nA. SX01P6 operates with input voltage range from 2.2V to 5.5V and it is able to deliver up 600mA output current`,
      `The SX01P6 provides programmable output voltages between 0.7V to 3.3V. The NERO SwitcherTM provides high efficiency down to 10uA load current and rapid load transient responses with switching frequency up to 2MHz at heavy load`,
    ],
    applications: ["IoT", "Wearabl", "Bluetooth", "Battery Powered Devices"],
    pictures: ["SX01P6-1.png"],
  },
  SX01P6B: {
    title: "SX01P6B",
    subTitle:
      "6.5V NERO SWITHERTM (Nano-IQ Efficient Rapid Output) 600mA Step-Down Regulator",
    features: [
      "Input Voltage Range from 2.2V to 6.5V Operates with 4AA Battery",
      "Output Voltage Range from 0.7V to 3.3V",
      "Up to 600mA Output Current",
      "Up to 96% Efficiency",
      "88% Efficiency at 10uA Load Current",
      "NERO SwitcherTM Power Save Mode 360nA Quiescent Current at Light Load",
      "NERO SwitcherTM Rapid Output Load Transient Responses",
      "Internal compensated",
      "2MHz Switching Frequency at PWM",
      "Output voltages options from 0.7V to 3.3V",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `The SX01P6B is a high efficiency step down regulator with ultra-low quiescent current of typical 360nA. SX01P6B operates with input voltage range from 2.2V to 6.5V and it is able to deliver up 600mA output current`,
      `The SX01P6B provides programmable output voltages between 0.7V to 3.3V. The NERO SwitcherTM provides high efficiency down to 10uA load current and rapid load transient responses with switching frequency up to 2MHz at heavy load`,
    ],
    applications: ["IoT", "Wearabl", "Bluetooth", "Battery Powered Devices"],
    pictures: ["SX01P6B-1.png"],
  },
  SX0106: {
    title: "SX0106",
    subTitle: "5V 6A High Frequency Buck, Rapid output load Transient Response",
    features: [
      "Input Voltage Range from 2.2V to 5.5V",
      "I2C Controlled Output Voltage Range from 0.5V to 2.4 V (3.3V offered by SX0108A output voltage adjustable version)",
      "Up to 6A Output Current",
      "Up to 95% Efficiency",
      "Power Save Mode 30µA Quiescent Current at Light Load",
      "HERO SWITCHERTM Rapid Output Load Transient Responses",
      "HERO SWITCHERTM Programable 2-4.5MHz Switching Frequency at PWM",
      "Internal Compensated",
      "Startup output voltage selection",
      "I2C slave address selection",
      "Dynamic Voltage Scaling",
      "Fault Interrupt Output",
      "2mm x 2.5mm DFN Package",
    ],
    descriptions: [
      `The SX0106 is a high efficiency step down regulator with up to 4.5MHz switching frequency. SX0106 operates with input voltage range from 2.2V to 5.5V and it is able to deliver 6A output current`,
      `With the I2C interface, SX0106 provides programmable output voltages between 0.5V to 2.4V. Programmable frequency from 2MHz to 4.5MHz. Programmable 25KHz switching limit for low acoustic noise application. And, programmable force PWM mode`,
      `The SX0106 RID_IRQn pin is a triple functions pin. It configures the initial output voltage as well as the slave address. After start up the pin becomes the interrupt output`,
    ],
    applications: [
      "Telecom",
      "Optical modules",
      "SSD",
      "Power supply for FPGAs, CPUs, and ASICs",
    ],
    pictures: ["SX0106-1.png"],
  },
  SX0108: {
    title: "SX0108",
    subTitle: "5V 8A High Frequency Buck, Rapid output load Transient Response",
    features: [
      "Input Voltage Range from 2.2V to 5.5V",
      "I2C Controlled Output Voltage Range from 0.5V to 2.4 V (3.3V offered by SX0108A output voltage adjustable version)",
      "Up to 8A Output Current",
      "Up to 95% Efficiency",
      "Power Save Mode 30µA Quiescent Current at Light Load",
      "HERO SWITCHERTM Rapid Output Load Transient Responses",
      "HERO SWITCHERTM Programable 2-4.5MHz Switching Frequency at PWM",
      "Internal Compensated",
      "Startup output voltage selection",
      "I2C slave address selection",
      "Dynamic Voltage Scaling",
      "Fault Interrupt Output",
      "2mm x 2.5mm DFN Package",
    ],
    descriptions: [
      `The SX0108 is a high efficiency step down regulator with up to 4.5MHz switching frequency. SX0108 operates with input voltage range from 2.2V to 5.5V and it is able to deliver 6A output current`,
      `With the I2C interface, SX0108 provides programmable output voltages between 0.5V to 2.4V. Programmable frequency from 2MHz to 4.5MHz. Programmable 25KHz switching limit for low acoustic noise application. And, programmable force PWM mode`,
      `The SX0108 RID_IRQn pin is a triple functions pin. It configures the initial output voltage as well as the slave address. After start up the pin becomes the interrupt output`,
    ],
    applications: [
      "Telecom",
      "Optical modules",
      "SSD",
      "Power supply for FPGAs, CPUs, and ASICs",
    ],
    pictures: ["SX0108-1.png"],
  },
  SX1106: {
    title: "SX1106",
    subTitle:
      "12V 6A High Frequency Buck, Rapid output load Transient Response ",
    features: [
      "Input Voltage Range from 3.6V to 18V",
      "Output Voltage Range from 0.46V to 5.5 V",
      "Up to 6A Output Current",
      "Up to 95% Efficiency",
      "Power Save Mode 40uA Quiescent Current at Light Load",
      "SHERO SWITCHERTM Rapid Output Load Transient Responses",
      "SHERO SWITCHERTM Programable 1-4MHz Switching Frequency at PWM",
      "Internal Compensated",
      "Selectable Soft-start Time",
      "Dynamic Voltage Scaling",
      "I2C slave Address Selection",
      "Fault Interrupt Output",
      "2mm x 3mm DFN and 3mm x 3mm DFN Package",
    ],
    descriptions: [
      `The SX1106 is a high efficiency step down regulator with up to 4MHz switching frequency. SX1106 operates with input voltage range from 3.6V to 18V and it is able to deliver 6A output current`,
      `With the I2C interface, SX1106 provides programmable reference voltages between 0.46V to 0.74V. Programmable frequency from 1MHz to 4MHz. Programmable 25KHz switching limit for low acoustic noise application. And, programmable force PWM mode`,
      `The SX1106 RID_IRQn pin is a triple functions pin. It configures the initial frequency as well as the slave address. After start up the pin becomes the interrupt output`,
    ],
    applications: [
      "5G Module and TV",
      "Telecom and Networking Equipment",
      "Enterprise SSD and Laptop DDR",
      "Power Supply for FPGAs, CPUs, and ASICs",
    ],
    pictures: ["SX1106-1.png"],
  },

  SX1108: {
    title: "SX1108",
    subTitle:
      "12V 8A High Frequency Buck, Rapid output load Transient Response ",
    features: [
      "Input Voltage Range from 3.6V to 18V",
      "Output Voltage Range from 0.46V to 5.5 V",
      "Up to 8A Output Current",
      "Up to 95% Efficiency",
      "Power Save Mode 40uA Quiescent Current at Light Load",
      "SHERO SWITCHERTM Rapid Output Load Transient Responses",
      "SHERO SWITCHERTM Programable 1-4MHz Switching Frequency at PWM",
      "Internal Compensated",
      "Selectable Soft-start Time",
      "Dynamic Voltage Scaling",
      "I2C slave Address Selection",
      "Fault Interrupt Output",
      "2mm x 3mm DFN and 3mm x 3mm DFN Package",
    ],
    descriptions: [
      `The SX1108 is a high efficiency step down regulator with up to 4MHz switching frequency. SX1108 operates with input voltage range from 3.6V to 18V and it is able to deliver 8A output current`,
      `With the I2C interface, SX1106 provides programmable reference voltages between 0.46V to 0.74V. Programmable frequency from 1MHz to 4MHz. Programmable 25KHz switching limit for low acoustic noise application. And, programmable force PWM mode`,
      `The SX1108 RID_IRQn pin is a triple functions pin. It configures the initial frequency as well as the slave address. After start up the pin becomes the interrupt output`,
    ],
    applications: [
      "5G Module and TV",
      "Telecom and Networking Equipment",
      "Enterprise SSD and Laptop DDR",
      "Power Supply for FPGAs, CPUs, and ASICs",
    ],
    pictures: ["SX1108-1.png"],
  },
  SX03P8: {
    title: "SX03P8",
    subTitle: "800nA Low Iq Boost ",
    features: [
      "Input Voltage Range from 0.9V to 5.5V Operates with alkaline, NiMH, Li-Mn, and Li-Ion battery",
      "Output Voltage Range from 1.8V to 5.5V",
      "Up to 880mA Output Current",
      "Up to 94% Efficiency",
      "60% Efficiency at 1uA Load Current",
      "NERO SWITCHERTM Power Save Mode 800nA Quiescent Current at Light Load",
      "NERO SWITCHERTM Rapid Output Load Transient Responses",
      "Internal compensated",
      "2MHz Switching Frequency at PWM",
      "1.4mm x 1.3mm 6-Ball WLCSP Package and 2mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `The SX03P8 is a high efficiency step up regulator with ultra-low quiescent current of typical 800nA. SX03P8 operates with input voltage range from 0.9V to 5.5V and it is able to deliver up 880mA output current`,
      `The SX03P8 provides both adjustable output voltages and fixed output voltage versions between 1.8V to 5.5V`,
      `NEROT@TM  control provides high efficiency down to 10uA load current and rapid load transient responses with switching frequency up to 2MHz`,
    ],
    applications: ["IOT", "Wearable", "Battery-Powered Device"],
    pictures: ["SX03P8-1.png"],
  },
  SX0603: {
    title: "SX0603",
    subTitle: "High PSRR, Low Output Noise , 300mA LDO ",
    features: [
      "Input Voltage Range from 1.65V to 6V",
      "Output Voltage Range from 0.8V to 5.5V",
      "Up to 300mA Output Current",
      "High PSRR:",
      "1KHz：  103dB",
      "10KHz： 86dB",
      "100KHz：75dB",
      "1MHz：  51dB",
      "8uA Quiescent Current",
      "8uV Output Noise",
      "0.66mm x 0.66mm 4-Ball WLCSP，1mm x 1mm DFN-4L和SOT23-5",
    ],
    descriptions: [
      `SX0603 is an ultrasmall low voltage drop linear regulator (LDO) that can provide 300mA output current. The input voltage range is from 1.65V to 6V, and the output voltage range is from 0.8V to 5.5V`,
      `SX0603 can provide very low output noise, high PSRR, and excellent Load Transient Responses, making it suitable for applications of RF and other sensitive analog circuits`,
      `SX0603 offers three miniaturized packages: WLCSP 0.66mm x 0.66mm, DFN 1mm x 1mm, and SOT23-5. By using small ceramic capacitors, stable output can be maintained.`,
    ],
    applications: [
      "Digital Camera",
      "RF",
      "VCO, ADC, DAC, LVDS, SerDes",
      "Medical equipment",
    ],
    pictures: ["SX0603-1.png"],
  },

  SX0620: {
    title: "SX0620",
    subTitle: "High-Accuracy (0.75%), Low Output Noise(4uV) , 2A LDO",
    features: [
      "Low dropout: 200mV (max) at 2A",
      "Accuracy over line, load, and temperature with BIAS: 0.75% (max)",
      "Output voltage noise: 4μVRMS at 0.8V output",
      "Input voltage range:",
      "– Without BIAS: 1.4V to 7V",
      "– With BIAS: 1.1V to 7V",
      "output voltage range: 0.8V to 5.2V",
      "Power-supply ripple rejection: 40 dB at 500 kHz",
      "Excellent load transient response",
      "Adjustable soft-start inrush control",
      "Open-drain power-good (PG) output",
      "3.5mm x 3.5mm QFN，5mm x 5mm QFN",
    ],
    descriptions: [
      `The SX0620 is a high accuracy, low-noise (4 μVRMS)and lowdropout linear regulator (LDO) . The SX0620 output voltage is pin programmable from 0.8 V to 3.95 V with a 50-mV resolution, and adjustable from 0.8 V to 5.2 V using an external resistor divider. The SX0620 output voltage is pin programmable from 0.5 V to 2.075 V with a 25-mV resolution, and adjustable from 0.5 V to 5.2 V using an external resistor divider. `,
      `The SX0620 is suitable for application which needs high accuracy, low noise and high current power supply, such as analog-to-digital converters (ADCs), digital-to -analog converters (DACs) and RF components.`,
      `With very high accuracy, remote sensing, and soft-start capabilities to reduce inrush current, the SX0620 ensures the optimal system performance for powering digital loads such as FPGAs, DSPs and ASICs.`,
    ],
    applications: [
      "Macro remote radio units (RRU)",
      "Outdoor backhaul units",
      "Active antenna system mMIMO (AAS)",
      "Ultrasound scanners",
      "Lab and field instrumentation",
      "Sensor, imaging, and radar",
    ],
    pictures: ["SX0620-1.png"],
  },

  SX0630: {
    title: "SX0630",
    subTitle: "High-Accuracy (0.75%), Low Output Noise(4uV) , 3A LDO",
    features: [
      "Low dropout: 200 mV (max) at 3A",
      "Accuracy over line, load, and temperature with BIAS: 0.75% (max)",
      "Output voltage noise: 4μVRMS at 0.8V output",
      "Input voltage range:",
      "– Without BIAS: 1.4V to 7V",
      "– With BIAS: 1.1V to 7V",
      "output voltage range: 0.8V to 5.2V",
      "Power-supply ripple rejection: 40 dB at 500 kHz",
      "Excellent load transient response",
      "Adjustable soft-start inrush control",
      "Open-drain power-good (PG) output",
      "3.5mm x 3.5mm QFN，5mm x 5mm QFN",
    ],
    descriptions: [
      `The SX0630 is a high accuracy, low-noise (4 μVRMS)and lowdropout linear regulator (LDO) . The SX0630 output voltage is pin programmable from 0.8 V to 3.95 V with a 50-mV resolution, and adjustable from 0.8 V to 5.2 V using an external resistor divider. The SX0630 output voltage is pin programmable from 0.5 V to 2.075 V with a 25-mV resolution, and adjustable from 0.5 V to 5.2 V using an external resistor divider. `,
      `The SX0630 is suitable for application which needs high accuracy, low noise and high current power supply, such as analog-to-digital converters (ADCs), digital-to -analog converters (DACs) and RF components.`,
      `With very high accuracy, remote sensing, and soft-start capabilities to reduce inrush current, the SX0630 ensures the optimal system performance for powering digital loads such as FPGAs, DSPs and ASICs.`,
    ],
    applications: [
      "Macro remote radio units (RRU)",
      "Outdoor backhaul units",
      "Active antenna system mMIMO (AAS)",
      "Ultrasound scanners",
      "Lab and field instrumentation",
      "Sensor, imaging, and radar",
    ],
    pictures: ["SX0630-1.png"],
  },

  SX0103M: {
    title: "SX0103M",
    subTitle: "2.4mm x 3mm 5V 3A Power Module ",
    features: [
      "VIN operation ranges from 2.2V to 5.5V",
      "Up to 3A Continuous Operating Current",
      "Very low quiescent current of 30uA at Light Loads",
      "Very low shutdown current, maximum 1uA",
      "Low Dropout voltage",
      "No thermal derating up to max load over operating temperature range",
      "I2C Programable VOUT ranges from 0.5V to 2.4V with 1% accuracy (CCM) over all operating line and temperature conditions",
      "IRQn to program I2C slave address with external resistor",
      "Programable modes of operation (PFM or Force PWM or 25KHz Limit for Low Acoustic noise applications)",
      "Dynamic Voltage Scaling",
      "Thermal, Over-Current, Short-Circuit, Under-Voltage, and Pre-Bias Protections",
      "Very Small, Fully Iintegrated With Well-Optimized Form Factor, Open-Top Module (2.4mm x 3.0mm x 1.97mm)",
    ],
    descriptions: [
      `The SX0103M is a fully integrated, high efficiency switch-mode buck converter module with I²C interface. It operates with an input voltage range from 2.2V to 5.5V, with very low dropout voltage of 250mV, and is able to deliver 3A of output current`,
      `The I²C interface provides the end user to program output voltages between 0.5V to 2.4V, program a forced PWM mode regardless of load current, program a 25KHz switching limit for low acoustic noise applications, and program dynamic voltage scaling. The I²C interface also allows the end user the ability to monitor fault conditions and status of the converter module`,
      `The SX0103M works in PFM mode in light load operation. Switching frequency is reduced, and automatically maintains a high efficiency, while inductor current drops almost to zero`,
      `The SX0103M reverts to PWM mode once the output current exceeds the critical CCM (Constant Current Mode) threshold. Afterward, switching frequency remains fairly constant over the output current range.`,
    ],
    applications: [
      "Optical Modules and Optical Networks",
      "Medical devices and Patient Monitoring devices",
      "Test and Measurement",
      "Instrumentation",
      "Telecommunication",
    ],
    pictures: ["SX0103M-1.png"],
  },
  SX0104M: {
    title: "SX0104M",
    subTitle: "2.4mm x 3mm 5V 4A Power Module ",
    features: [
      "VIN operation ranges from 2.2V to 5.5V",
      "Up to 4A Continuous Operating Current",
      "Very low quiescent current of 30uA at Light Loads",
      "Very low shutdown current, maximum 1uA",
      "Low Dropout voltage",
      "No thermal derating up to max load over operating temperature range",
      "I2C Programable VOUT ranges from 0.5V to 2.4V with 1% accuracy (CCM) over all operating line and temperature conditions",
      "IRQn to program I2C slave address with external resistor",
      "Programable modes of operation (PFM or Force PWM or 25KHz Limit for Low Acoustic noise applications)",
      "Dynamic Voltage Scaling",
      "Thermal, Over-Current, Short-Circuit, Under-Voltage, and Pre-Bias Protections",
      "Very Small, Fully Iintegrated With Well-Optimized Form Factor, Open-Top Module (2.4mm x 3.0mm x 1.97mm)",
    ],
    descriptions: [
      `The SX0104M is a fully integrated, high efficiency switch-mode buck converter module with I²C interface. It operates with an input voltage range from 2.2V to 5.5V, with very low dropout voltage of 250mV, and is able to deliver 4A of output current`,
      `The I²C interface provides the end user to program output voltages between 0.5V to 2.4V, program a forced PWM mode regardless of load current, program a 25KHz switching limit for low acoustic noise applications, and program dynamic voltage scaling. The I²C interface also allows the end user the ability to monitor fault conditions and status of the converter module`,
      `The SX0104M works in PFM mode in light load operation. Switching frequency is reduced, and automatically maintains a high efficiency, while inductor current drops almost to zero`,
      `The SX0104M reverts to PWM mode once the output current exceeds the critical CCM (Constant Current Mode) threshold. Afterward, switching frequency remains fairly constant over the output current range.`,
    ],
    applications: [
      "Optical Modules and Optical Networks",
      "Medical devices and Patient Monitoring devices",
      "Test and Measurement",
      "Instrumentation",
      "Telecommunication",
    ],
    pictures: ["SX0104M-1.png"],
  },

  SX1104M: {
    title: "SX1104M",
    subTitle: "12V 4A step-down Power Module",
  },

  SX6001: {
    title: "SX6001",
    subTitle:
      "Three 4A step-down switching regulators，regulator，Two LDO regulators",
  },

  SX6000: {
    title: "SX6000",
    subTitle:
      "Four 5A step-down switching regulators，Programmable dual-phase and single-phase regulator，Three LDO regulators",
  },
};
export const CnProductList = {
  SX1801: {
    title: "SX1801",
    subTitle: "65nA低功耗负载开关",
    features: [
      "输入电压从1V 到 5.5V",
      "高达2A输出电流",
      "60mΩ低内阻",
      "内置200uS软起动",
      "65nA 超低待机功耗",
      "内置反向电流保护",
      "150Ω快速放电电阻",
      "输出短路保护",
      "0.77*0.77 WLCSP-4B封装",
    ],
    descriptions: [
      `SX1801是一款超高效负载开关，具有典型的80nA超低静态电流。SX1801的输入电压范围从1.0V到5.5V。它能够提供2A的输出电流。`,
      `SX1801提供超低静态电流IQ和关断电流ISD。通过减少泄漏电流，提高了整个系统的功率效率和运行寿命。`,
      `SX1801提供极低的60毫欧导通电阻。反向电流保护和输出短路保护。反向保护在负载开关的导通和断开状态下都会激活。`,
    ],
    applications: ["物联网", "可穿戴", "固态硬盘", "电池供电类产品"],
    pictures: ["SX1801-1.png"],
  },
  SX01P3: {
    title: "SX01P3",
    subTitle: "300mA，360nA低功耗降压DCDC",
    features: [
      "输入电压从2.2V 到 5.5V, 可工作在单节锂电池或者LiMnO2",
      "输出电压从0.7V 到 3.3V,通过Vset电阻选择",
      "高达300mA输出电流",
      "高达96%的转换效率",
      "10uA负载时效率高达88%",
      "360nA 超低待机功耗",
      "NERO SwitcherTM 可提供快速的动态响应",
      "2MHz 开关频率",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `SX01P3是一款高效降压转换器，具有360nA的超低静态电流。SX01P3的输入电压范围为2.2V至5.5V，输出电流可达300mA。`,
      `SX01P3提供0.7V至3.3V之间的可调输出电压，输出电压通过Vset电阻来选择，不需要分压电阻，能有效降低整体功耗。`,
      `NERO SwitcherTM可在10uA的负载电流时提供高效率，高达2MHz的工作频率能提供快速负载瞬态响应。`,
      `SX01P3只需少量的外部元器件，采用超小的0.91*1.33 6球CSP和1.5*2 DFN封装。`,
      `SX01P3适合用于各种低功耗应用，包括可穿戴、助听器、物联网、GPS定位追踪等各种电池供电长续航的产品。`,
    ],
    applications: ["物联网", "可穿戴产品", "低功耗蓝牙", "电池供电类产品"],
    pictures: ["SX01P3-1.png"],
  },
  SX01P6: {
    title: "SX01P6",
    subTitle: "600mA，360nA低功耗降压DCDC",
    features: [
      "输入电压从2.2V 到 5.5V, 可工作在单节锂电池或者LiMnO2",
      "输出电压从0.7V 到 3.3V,通过Vset电阻选择",
      "高达300mA输出电流",
      "高达96%的转换效率",
      "10uA负载时效率高达88%",
      "360nA 超低待机功耗",
      "NERO SwitcherTM 可提供快速的动态响应",
      "2MHz 开关频率",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `SX01P6是一款高效降压转换器，具有360nA的超低静态电流。SX01P3的输入电压范围为2.2V至5.5V，输出电流可达600mA。`,
      `SX01P6提供0.7V至3.3V之间的可调输出电压，输出电压通过Vset电阻来选择，不需要分压电阻，能有效降低整体功耗。`,
      `NERO SwitcherTM可在10uA的负载电流时提供高效率，高达2MHz的工作频率能提供快速负载瞬态响应。`,
      `SX01P6只需少量的外部元器件，采用超小的0.91*1.33 6球CSP和1.5*2 DFN封装。`,
      `SX01P6适合用于各种低功耗应用，包括可穿戴、助听器、物联网、GPS定位追踪等各种电池供电长续航的产品。`,
    ],
    applications: ["物联网", "可穿戴产品", "低功耗蓝牙", "电池供电类产品"],
    pictures: ["SX01P6-1.png"],
  },
  SX01P6B: {
    title: "SX01P6B",
    subTitle: "6.5V 600mA，360nA低功耗降压DCDC",
    features: [
      "输入电压从2.2V 到 6.5V, 可工作在4节干电",
      "输出电压从0.7V 到 3.3V,通过Vset电阻选择",
      "高达300mA输出电流",
      "高达96%的转换效率",
      "10uA负载时效率高达88%",
      "360nA 超低待机功耗",
      "NERO SwitcherTM 可提供快速的动态响应",
      "2MHz 开关频率",
      "0.91mm x 1.33mm 6-Ball WLCSP Package and 1.5mm x 2mm DFN-6L Package",
    ],
    descriptions: [
      `SX01P6B是一款高效降压转换器，具有360nA的超低静态电流。SX01P3的输入电压范围为2.2V至6.5V，输出电流可达600mA。`,
      `SX01P6B提供0.7V至3.3V之间的可调输出电压，输出电压通过Vset电阻来选择，不需要分压电阻，能有效降低整体功耗。`,
      `NERO SwitcherTM可在10uA的负载电流时提供高效率，高达2MHz的工作频率能提供快速负载瞬态响应。`,
      `SX01P6B只需少量的外部元器件，采用超小的0.91*1.33 6球CSP和1.5*2 DFN封装。`,
      `SX01P6B适合用于各种低功耗应用，包括可穿戴、助听器、物联网、GPS定位追踪等各种电池供电长续航的产品。`,
    ],
    applications: ["物联网", "可穿戴产品", "低功耗蓝牙", "电池供电类产品"],
    pictures: ["SX01P6B-1.png"],
  },
  SX0106: {
    title: "SX0106",
    subTitle: "5V, 6A高频降压DCDC，快速动态响应",
    features: [
      "输入电压从 2.2V 到 5.5V",
      "I2C 控制输出电压，从0.5V 到 2.4 V (可调输出电压版本可提供3.3V输出电压",
      "6A持续输出电流",
      "高达95%转换效率",
      "30µA静态电流",
      "HERO SWITCHERTM可提供快速动态响应",
      "HERO SWITCHERTM 提供开关频率在2-4.5MHz调整",
      "内置补偿电路",
      "上电输出电压选择",
      "I2C从地址选择",
      "动态输出电压调整（DVS）",
      "Fault中断输出",
      "2mm x 2.5mm DFN Package",
    ],
    descriptions: [
      `SX0106是一款高效降压调节器，开关频率高达4.5MHz。SX0106在2.2V至5.5V的输入电压范围内工作，能够提供6A的输出电流`,
      `SX0106具有I2C接口，可提供0.5V至2.4V之间的可编程输出电压。可编程频率为2MHz至4.5MHz。可编程25KHz开关限制，适用于低噪声应用。以及可编程的强制PWM模式。`,
      `SX0106 RID_IRQn引脚是一个三重功能引脚。它设置初始输出电压以及从地址。启动后，该引脚成为中断输出`,
    ],
    applications: [
      "网络通信",
      "光模组",
      "固态硬盘",
      "FPGAs, CPUs, and ASICs供电",
    ],
    pictures: ["SX0106-1.png"],
  },
  SX0108: {
    title: "SX0108",
    subTitle: "5V, 8A高频降压DCDC，快速动态响应",
    features: [
      "输入电压从 2.2V 到 5.5V",
      "I2C 控制输出电压，从0.5V 到 2.4 V (可调输出电压版本可提供3.3V输出电压",
      "8A持续输出电流",
      "高达95%转换效率",
      "30µA静态电流",
      "HERO SWITCHERTM可提供快速动态响应",
      "HERO SWITCHERTM 提供开关频率在2-4.5MHz调整",
      "内置补偿电路",
      "上电输出电压选择",
      "I2C从地址选择",
      "动态输出电压调整（DVS）",
      "Fault中断输出",
      "2mm x 2.5mm DFN Package",
    ],
    descriptions: [
      `SX0108是一款高效降压调节器，开关频率高达4.5MHz。SX0108在2.2V至5.5V的输入电压范围内工作，能够提供8A的输出电流`,
      `SX0108具有I2C接口，可提供0.5V至2.4V之间的可编程输出电压。可编程频率为2MHz至4.5MHz。可编程25KHz开关限制，适用于低噪声应用。以及可编程的强制PWM模式。`,
      `SX0108 RID_IRQn引脚是一个三重功能引脚。它设置初始输出电压以及从地址。启动后，该引脚成为中断输出`,
    ],
    applications: [
      "网络通信",
      "光模组",
      "固态硬盘",
      "FPGAs, CPUs, and ASICs供电",
    ],
    pictures: ["SX0108-1.png"],
  },
  SX1106: {
    title: "SX1106",
    subTitle: "12V, 6A高频降压DCDC，快速动态响应",
    features: [
      "输入电压范围从 3.6V 到 18V",
      "输出电压范围从 0.46V 到 5.5V",
      "6A持续输出电流",
      "高达95%转换效率",
      "40uA静态电流",
      "SHERO SWITCHERTM 提供快速动态响应",
      "SHERO SWITCHERTM 提供开关频率从1-4MHz调整",
      "内置补偿电路",
      "可选软起动时间",
      "动态输出电压调整（DVS）",
      "I2C 从地址选择",
      "Fault 中断输出",
      "2mm x 3mm DFN，3mm x 3mm DFN 封装",
    ],
    descriptions: [
      `SX1106是一款高效降压调节器，开关频率高达4MHz。SX1106在3.6V至18V的输入电压范围内工作，能够提供6A的输出电流`,
      `SX1106具有I2C接口，可提供0.46V至0.74V之间的可编程参考电压。可编程频率为1MHz至4MHz。可编程25KHz开关限制，适用于低噪声应用。以及可编程的强制PWM模式`,
      `SX1106 RID_IRQn引脚是一个三重功能引脚。它配置初始频率以及从属地址。启动后，引脚成为中断输出`,
    ],
    applications: [
      "5G通信",
      "4K电视和视频监控",
      "企业级固态硬盘，DDR供电",
      "FPGAs, CPUs, and ASICs供电",
    ],
    pictures: ["SX1106-1.png"],
  },
  SX1108: {
    title: "SX1108",
    subTitle: "12V, 8A高频降压DCDC，快速动态响应",
    features: [
      "输入电压范围从 3.6V 到 18V",
      "输出电压范围从 0.46V 到 5.5V",
      "8A持续输出电流",
      "高达95%转换效率",
      "40uA静态电流",
      "SHERO SWITCHERTM 提供快速动态响应",
      "SHERO SWITCHERTM 提供开关频率从1-4MHz调整",
      "内置补偿电路",
      "可选软起动时间",
      "动态输出电压调整（DVS）",
      "I2C 从地址选择",
      "Fault 中断输出",
      "2mm x 3mm DFN，3mm x 3mm DFN 封装",
    ],
    descriptions: [
      `SX1108是一款高效降压调节器，开关频率高达4MHz。SX1108在3.6V至18V的输入电压范围内工作，能够提供8A的输出电流`,
      `SX1108具有I2C接口，可提供0.46V至0.74V之间的可编程参考电压。可编程频率为1MHz至4MHz。可编程25KHz开关限制，适用于低噪声应用。以及可编程的强制PWM模式`,
      `SX1108 RID_IRQn引脚是一个三重功能引脚。它配置初始频率以及从属地址。启动后，引脚成为中断输出`,
    ],
    applications: [
      "5G通信",
      "4K电视和视频监控",
      "企业级固态硬盘，DDR供电",
      "FPGAs, CPUs, and ASICs供电",
    ],
    pictures: ["SX1108-1.png"],
  },
  SX03P8: {
    title: "S03P8",
    subTitle: "800nA低功耗升压DCDC",
    features: [
      "输入电压从0.9V 到 5.5V, 可工作在单节AA，NiMH, Li-Mn和 锂电池",
      "输出电压从1.8V 到 3.3V,可提供固定输出电压",
      "高达880mA输出电流",
      "高达94%的转换效率",
      "10uA负载时效率高达88%",
      "800nA 超低待机功耗",
      "NERO SwitcherTM 可提供快速的动态响应",
      "内置补偿电路",
      "2MHz 开关频率",
      "1.4mm x 1.3mm 6-Ball WLCSP和2mm x 2mm DFN-6L",
    ],
    descriptions: [
      `SX03P8是一款高效升压调节器，具有典型800nA的超低静态电流。SX03P8在0.9V至5.5V的输入电压范围内工作，能够提供高达880mA的输出电流`,
      `SX03P8提供1.8V至5.5V之间的可调输出电压和固定输出电压版本`,
      `NERO控制提供高达10uA的高效负载电流和高达2MHz的开关频率的快速负载瞬态响应`,
      `SX03P8可以应用在各种电池供电低功耗应用，如鼠标，键盘，便携式医疗设备，可穿戴，物联网等等`,
    ],
    applications: ["物联网", "可穿戴产品", "低功耗蓝牙", "电池供电类产品"],
    pictures: ["SX03P8-1.png"],
  },
  SX0603: {
    title: "SX0603",
    subTitle: "超高PSRR，低噪声 300mA LDO",
    features: [
      "输入电压从1.65V 到 6V",
      "输出电压从0.8V 到 5.5V",
      "300mA输出电流",
      "超高PSRR:",
      "1KHz：  103dB",
      "10KHz： 86dB",
      "100KHz：75dB",
      "1MHz：  51dB",
      "8uA 低待机功耗",
      "8uV 输出噪声",
      "0.66mm x 0.66mm 4-Ball WLCSP，1mm x 1mm DFN-4L和SOT23-5封装",
    ],
    descriptions: [
      `SX0603是一款超小型低压降线性稳压器（LDO），可提供300mA的输出电流。SX0306在01.65V至6V的输入电压范围内工作，输出电压范围从0.8V至5.5V`,
      `SX0603可提供低输入噪声，高PSRR和出色的负载和瞬态响应，适合用在射频和其他敏感模拟电路`,
      `SX0603提供WLCSP 0.66mm x 0.66mm，DFN 1mm x 1mm，SOT23-5三种小型化封装，使用小陶瓷电容即可保持稳定输出，可实现小尺寸的总体解决方案`,
    ],
    applications: [
      "摄像头应用",
      "射频",
      "压控振荡器（VCO），模数转换器（ADC），数模转换器（DAC），串化解串器（SerDes）",
      "医疗",
    ],
    pictures: ["SX0603-1.png"],
  },
  SX0620: {
    title: "SX0620",
    subTitle: "高精度(0.75%), 低噪声(4uV) , 2A LDO",
    features: [
      "低压差: 2A 电流时为200mV（最大值）",
      "线路、负载和温度范围内的精度（有偏置）：0.75%（最大值）",
      "输出电压噪声：4μVRMS（输出电压为 0.8V 时）",
      "输入电压范围:",
      "– 无偏置: 1.4V to 7V",
      "– 有偏置: 1.1V to 7V",
      "输出电压范围: 0.8V to 5.2V",
      "电源纹波抑制: 500kHz时为40dB",
      "出色的负载瞬态响应",
      "可调软启动浪涌控制",
      "开漏电源正常 (PG) 输出",
      "3.5mm x 3.5mm QFN，5mm x 5mm QFN",
    ],
    descriptions: [
      `SX0620是一款高精度、低噪声（4μVRMS）和低损耗线性稳压器（LDO）。SX0620输出电压可在0.8 V至3.95 V范围内以步长50mV进行引脚编程，并可使用外部电阻分压从0.8V到5.2V范围内调节。SX0620输出电压可在0.5V至2.075V范围内以分辨率为25mV进行引脚编程，并可使用外部电阻分压从0.5V到5.2V范围内调节。`,
      `SX0620适用于需要高精度、低噪声和高电流电源的应用，如模数转换器（ADC）、数模转换器（DAC）和RF组件。`,
      `SX0620具有非常高的精度和软启动功能，可减少浪涌电流，确保为FPGA、DSP和ASIC等数字负载供电的最佳系统性能。`,
    ],
    applications: [
      "宏远程无线电单元 (RRU)",
      "室外回程单元",
      "有源天线系统 mMIMO (AAS)",
      "超声波扫描仪",
      "实验室和现场仪表",
      "传感器、成像和雷达",
    ],
    pictures: ["SX0620-1.png"],
  },
  SX0630: {
    title: "SX0630",
    subTitle: "高精度(0.75%), 低噪声(4uV) , 3A LDO",
    features: [
      "低压差: 3A 电流时为200mV（最大值）",
      "线路、负载和温度范围内的精度（有偏置）：0.75%（最大值）",
      "输出电压噪声：4μVRMS（输出电压为 0.8V 时）",
      "输入电压范围:",
      "– 无偏置: 1.4V to 7V",
      "– 有偏置: 1.1V to 7V",
      "输出电压范围: 0.8V to 5.2V",
      "电源纹波抑制: 500kHz时为40dB",
      "出色的负载瞬态响应",
      "可调软启动浪涌控制",
      "开漏电源正常 (PG) 输出",
      "3.5mm x 3.5mm QFN，5mm x 5mm QFN",
    ],
    descriptions: [
      `SX0630是一款高精度、低噪声（4μVRMS）和低损耗线性稳压器（LDO）。SX0630输出电压可在0.8 V至3.95 V范围内以步长50mV进行引脚编程，并可使用外部电阻分压从0.8V到5.2V范围内调节。SX0630输出电压可在0.5V至2.075V范围内以分辨率为25mV进行引脚编程，并可使用外部电阻分压从0.5V到5.2V范围内调节。`,
      `SX0630适用于需要高精度、低噪声和高电流电源的应用，如模数转换器（ADC）、数模转换器（DAC）和RF组件。`,
      `SX0630具有非常高的精度和软启动功能，可减少浪涌电流，确保为FPGA、DSP和ASIC等数字负载供电的最佳系统性能。`,
    ],
    applications: [
      "宏远程无线电单元 (RRU)",
      "室外回程单元",
      "有源天线系统 mMIMO (AAS)",
      "超声波扫描仪",
      "实验室和现场仪表",
      "传感器、成像和雷达",
    ],
    pictures: ["SX0630-1.png"],
  },

  SX0103M: {
    title: "S0103M",
    subTitle: "2.4mm x 3mm 5V 3A 电源模组",
    features: [
      "输入电压范围从2.2V到5.5V",
      "高达3A的持续工作电流",
      "30uA的极低静态电流",
      "极低关闭电流，最大1uA",
      "低输入输出压差",
      "在工作温度范围内，最大负载下无热降额",
      "I2C可编程VOUT范围从0.5V到2.4V，在所有工作和温度条件下具有1%的精度",
      "IRQn用外部电阻编程I2C从地址",
      "可编程操作模式（PFM或强制PWM或25KHz限制，用于低噪声应用）",
      "动态输出电压调整（DVS）",
      "过热、过流、短路、欠压和预偏置保护",
      "体积非常小，2.4mm x 3.0mm x 1.97mm ",
    ],
    descriptions: [
      `SX0103M是一款具有I²C接口的全集成高效开关降压转换器模块。它的输入电压范围为2.2V至5.5V，压降非常低，仅250mV，能够提供3A的输出电流`,
      `I²C接口为用户提供了对0.5V至2.4V之间的输出电压进行动态调节的能力，可设置开关频率；对于低噪声应用，可设置25KHz开关频率限制。I²C接口还允许最终用户监控该转换器模块的故障状况和状态`,
      `SX0103M在轻负载操作中以PFM模式工作。开关频率降低，并自动保持高效率，同时电感电流几乎降至零`,
      `一旦输出电流超过临界CCM（恒定电流模式）阈值，SX0103M将恢复到PWM模式。之后开关频率保持稳定`,
    ],
    applications: [
      "光模块和光网络",
      "医疗和病人监控设备",
      "仪器和仪表",
      "网络通信",
    ],
    pictures: ["SX0103M-1.png"],
  },
  SX0104M: {
    title: "SX0104M",
    subTitle: "2.4mm x 3mm 5V 4A 电源模组",
    features: [
      "输入电压范围从2.2V到5.5V",
      "高达4A的持续工作电流",
      "30uA的极低静态电流",
      "极低关闭电流，最大1uA",
      "低输入输出压差",
      "在工作温度范围内，最大负载下无热降额",
      "I2C可编程VOUT范围从0.5V到2.4V，在所有工作和温度条件下具有1%的精度",
      "IRQn用外部电阻编程I2C从地址",
      "可编程操作模式（PFM或强制PWM或25KHz限制，用于低噪声应用）",
      "动态输出电压调整（DVS）",
      "过热、过流、短路、欠压和预偏置保护",
      "体积非常小，2.4mm x 3.0mm x 1.97mm ",
    ],
    descriptions: [
      `SX0104M是一款具有I²C接口的全集成高效开关降压转换器模块。它的输入电压范围为2.2V至5.5V，压降非常低，仅250mV，能够提供4A的输出电流`,
      `I²C接口为用户提供了对0.5V至2.4V之间的输出电压进行动态调节的能力，可设置开关频率；对于低噪声应用，可设置25KHz开关频率限制。I²C接口还允许最终用户监控该转换器模块的故障状况和状态`,
      `SX0104M在轻负载操作中以PFM模式工作。开关频率降低，并自动保持高效率，同时电感电流几乎降至零`,
      `一旦输出电流超过临界CCM（恒定电流模式）阈值，SX0104M将恢复到PWM模式。之后开关频率保持稳定`,
    ],
    applications: [
      "光模块和光网络",
      "医疗和病人监控设备",
      "仪器和仪表",
      "网络通信",
    ],
    pictures: ["SX0104M-1.png"],
  },

  SX1104M: {
    title: "SX1104M",
    subTitle: "12V 4A 降压电源模组",
  },

  SX6001: {
    title: "SX6000",
    subTitle: "3路4A降压DCDC，2路LDO PMIC",
  },

  SX6000: {
    title: "SX6000",
    subTitle: "4路5A降压DCDC，DCDC支持双相并联使用，3路LDO PMIC",
  },
};

export const CnProductNameList = [
  {
    value: "负载开关",
    label: "负载开关",
    children: [
      {
        value: "SX1801",
        label: "2A",
      },
    ],
  },
  {
    value: "降压DCDC",
    label: "降压DCDC",
    children: [
      {
        value: "低功耗",
        label: "低功耗",
        children: [
          {
            value: "SX01P3",
            label: "5V",
            children: [
              {
                value: "SX01P3",
                label: "300mA",
              },
              {
                value: "SX01P6",
                label: "600mA",
              },
            ],
          },
          {
            value: "SX01P6",
            label: "6.5V",
            children: [
              {
                value: "SX01P6B",
                label: "600mA",
              },
            ],
          },
        ],
      },
      {
        value: "大电流",
        label: "大电流",
        children: [
          {
            value: "5V",
            label: "5V",
            children: [
              {
                value: "SX0106",
                label: "6A",
              },
              {
                value: "SX0108",
                label: "8A",
              },
            ],
          },

          {
            value: "12V",
            label: "12V",
            children: [
              {
                value: "SX1106",
                label: "6A",
              },
              {
                value: "SX1108",
                label: "8A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "升压DCDC",
    label: "升压DCDC",
    children: [
      {
        value: "低功耗",
        label: "低功耗",
        children: [
          {
            value: "SX03P8",
            label: "880mA",
          },
        ],
      },
    ],
  },
  {
    value: "High PSRR LDO",
    label: "High PSRR LDO",
    children: [
      {
        value: "SX0603",
        label: "300mA",
      },
      {
        value: "SX0620",
        label: "2A",
      },
      {
        value: "SX0630",
        label: "3A",
      },
    ],
  },
  {
    value: "电源模组",
    label: "电源模组",
    children: [
      {
        value: "5V",
        label: "5V",
        children: [
          {
            value: "SX0103M",
            label: "3A",
          },
          {
            value: "SX0104M",
            label: "4A",
          },
        ],
      },
      {
        value: "12V",
        label: "12V",
        children: [
          {
            value: "SX1104M",
            label: "4A",
          },
        ],
      },
    ],
  },
  {
    value: "PMIC",
    label: "PMIC",
    children: [
      {
        value: "SX6001",
        label: "5V",
      },
      {
        value: "SX6000",
        label: "12V",
      },
    ],
  },
];

export const EnProductNameList = [
  {
    value: "Load Switch",
    label: "Load Switch",
    children: [
      {
        value: "SX1801",
        label: "2A",
      },
    ],
  },
  {
    value: "Buck",
    label: "Buck",
    children: [
      {
        value: "Low Iq",
        label: "Low Iq",
        children: [
          {
            value: "SX01P3",
            label: "5V",
            children: [
              {
                value: "SX01P3",
                label: "300mA",
              },
              {
                value: "SX01P6",
                label: "600mA",
              },
            ],
          },
          {
            value: "SX01P6",
            label: "6.5V",
            children: [
              {
                value: "SX01P6B",
                label: "600mA",
              },
            ],
          },
        ],
      },
      {
        value: "High Current",
        label: "High Current",
        children: [
          {
            value: "5V",
            label: "5V",
            children: [
              {
                value: "SX0106",
                label: "6A",
              },
              {
                value: "SX0108",
                label: "8A",
              },
            ],
          },
          {
            value: "12V",
            label: "12V",
            children: [
              {
                value: "SX1106",
                label: "6A",
              },
              {
                value: "SX1108",
                label: "8A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: "Boost",
    label: "Boost",
    children: [
      {
        value: "Low Iq",
        label: "Low Iq",
        children: [
          {
            value: "SX03P8",
            label: "880mA",
          },
        ],
      },
    ],
  },
  {
    value: "High PSRR LDO",
    label: "High PSRR LDO",
    children: [
      {
        value: "SX0603",
        label: "300mA",
      },
      {
        value: "SX0620",
        label: "2A",
      },
      {
        value: "SX0630",
        label: "3A",
      },
    ],
  },
  {
    value: "Power Module",
    label: "Power Module",
    children: [
      {
        value: "5V",
        label: "5V",
        children: [
          {
            value: "SX0103M",
            label: "3A",
          },
          {
            value: "SX0104M",
            label: "4A",
          },
        ],
      },
      {
        value: "12V",
        label: "12V",
        children: [
          {
            value: "SX1104M",
            label: "4A",
          },
        ],
      },
    ],
  },
  {
    value: "PMIC",
    label: "PMIC",
    children: [
      {
        value: "SX6001",
        label: "5V",
      },
      {
        value: "SX6000",
        label: "12V",
      },
    ],
  },
];
