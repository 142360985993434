<template>
  <div>
    <home-area />

    <company-area :element-in-view="handleElementInView" />
    <technology-area :element-in-view="handleElementInView" />
    <jd-area />
    <contact-area :element-in-view="handleElementInView" />
  </div>
</template>

<script>
import {
  HomeArea,
  CompanyArea,
  TechnologyArea,
  JdArea,
  ContactArea,
} from "../../components";
export default {
  components: {
    HomeArea,
    CompanyArea,
    TechnologyArea,
    JdArea,
    ContactArea,
  },
  data() {
    return {};
  },

  methods: {
    handleElementInView(id, cb) {
      const elOffsetTop = document.getElementById(id).offsetTop + 0;
      const docScrollTop = document.documentElement.scrollTop;
      if (
        elOffsetTop >= docScrollTop &&
        elOffsetTop < docScrollTop + window.innerHeight
      ) {
        setTimeout(() => {
          cb();
        }, 0);
      }
    },
  },
};
</script>
